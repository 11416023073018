import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import menu from './menu';
import platform from './platform';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [menu, ...(platform.children.length > 0 ? [platform] : [])]
};

export default menuItems;
