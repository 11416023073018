import { IconCurrencyDollar, IconPick } from '@tabler/icons-react';
import { accountMatchLv } from 'security/token';
import { ADMIN_LEVELS } from 'utils/constants';

const ITEMS_TRANSACTION_ID = 'transactions';
const ITEMS_GM_ID = 'gm_tool';

const permissionItems = [
    {
        lv: ADMIN_LEVELS.PLATFORM_ADMIN,
        id: ITEMS_TRANSACTION_ID,
        title: 'Transactions',
        type: 'item',
        url: '/transactions',
        icon: IconCurrencyDollar,
        breadcrumbs: false
    },
    {
        lv: ADMIN_LEVELS.PLATFORM_GM,
        id: ITEMS_GM_ID,
        title: 'GM Tool',
        type: 'item',
        url: '/gmtool',
        icon: IconPick,
        breadcrumbs: false
    }
];

const platform = {
    id: 'platform',
    title: '69 Platform',
    type: 'group',
    get children() {
        return permissionItems.filter((item) => accountMatchLv(item.lv));
    }
};

export default platform;
